import React, { useRef, useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { App } from 'antd';
import { NotificationProvider } from 'store';
import { PANEL_URL } from 'config/config';
const AppWrapper = ({ children }) => {
  const ref = useRef();
  const theme = {
    token: {
      fontFamily: 'Roboto',
      colorPrimary: '#0186C2',
      borderRadius: '4px',
      components: {
        Button: {
          borderRadius: '4px',
        },
      },
    },
  };
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(window.location.search);
  let obj = {};
  // object.fromEntries <== doesnt support in all browsers
  for (const [key, value] of searchParams) {
    obj = {
      ...obj,
      [key]: value,
    };
  }
  useEffect(() => {
    if (
      (!obj.idp_init || !obj.req || !obj.sp)
      && (!obj.authType || !obj.sp)
      && obj.login === undefined
    ) {
      window.location.replace(
        `${PANEL_URL}`
      );
      setLoading(false);
    } else {
      setLoading(false);
    }
  });
  return (
    <ConfigProvider theme={theme} getPopupContainer={() => ref.current}>
      <App>
        <NotificationProvider>
          {!loading && <div ref={ref}>{children}</div>}
        </NotificationProvider>
      </App>
    </ConfigProvider>
  );
};
export default AppWrapper;
