const externalIdpsNames = (name) => {
  let finalName = '';
  switch(name) {
    case 'entra_id':
      finalName = 'Entra ID';
      break;
    default:
      finalName = name;
  }
  return finalName.substring(0,1).toLocaleUpperCase().concat(finalName.substring(1));
}

export {
  externalIdpsNames
}